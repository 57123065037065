.projects {
    width: 100%;
    height: 100%;
}

.cards-container {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 .2rem;
    max-width: 1200px;
    margin: 0 auto;
    z-index: 3;
}

.card {
    width: 22rem;
    display: flex;
    flex-direction: column;
    background-color: rgb(250, 237, 237);
    height: 37rem;
    box-shadow: .5rem .5rem 3rem rgba(0,0,0,0.4);
    margin-bottom: 20px;
    position: relative;
}

.card-img {
    width: 100%;
    height: 200px;    
    border-bottom: 2px solid black;
    /* object-fit: cover; */
}

.card h1 {
    margin-top: 10px;
    text-align: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
}

.card p {
    margin: 2.5%;
}

.card-details {
  text-align: left;
}

.card-info {
    text-align: left;
}

.bold {
    font-weight: bold;
}

.btn-container {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, -50%);
  display: flex;
}

.btn {
    color: white;
    padding: 10px 8px;
}

.gameoflife-btn {
    margin-top: 20px
}

@media (max-width: 400px) {
    .cards-container {
        text-align: center;
    }
    .gameoflife-btn {
      margin-top: 0px
  }
}