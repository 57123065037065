* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  font-family: 'Black Ops One', cursive;
}

body {
  font-size: 1rem;
  background-color: rgba(243, 234, 240, 0.952);
}

p {
  font-family: 'Raleway', sans-serif;
  line-height: 1.25;
  letter-spacing: 1.25px;
}

a {
  text-decoration: none;
}

a:hover {
  transition: ease-in-out 0.1s;
  cursor: pointer;
}

.navbar a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.btn {
  padding: 10px 8px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  background-color: var(--primary-color);
}

.btn:hover {
  opacity: 0.9;
  background-color: var(--secondary-color);
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
}

h3 {
  margin: 40px 0;
  display: flex;
  font-size: 3rem;
  color: var(--secondary-color);
}

h3:before,
h3:after {
  content: '';
  flex: 1;
  border-bottom: groove 3px var(--primary-color);
  margin: auto 1em;
}

@media (max-width: 800px) {
  .main-body {
    margin-left: 5rem;
    margin-right: 1rem;
  }
}

@media (max-width: 400px) {
  h3 {
    font-size: 2rem;
  }
}
