* {
  box-sizing: border-box;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.landing {
  position: relative;
  background-image: url('./trolltunga1tiny.jpg');
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: cover;
}

.dark-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.landing-inner {
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.hero-heading {
  font-size: 3.5rem;
  position: relative;
  color: var(--primary-color);
}

.hero-heading span {
  color: #ddd;
}

.hero-sub {
  font-size: 2rem;
  margin: 15px 0;
  width: 0 auto;
  position: relative;
  color: var(--secondary-color);
  display: flex;
}

h5 {
  text-align: center;
  color: #ddd;
  font-size: 16px;
}

h5 span {
  opacity: 0;
  display: inline-block;
  position: relative;
}

.start-btn {
  position: absolute;
  bottom: 4rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.start-btn:hover {
  color: var(--secondary-color);
}

@media (max-width: 400px) {
  .hero-heading {
    font-size: 2rem;
  }

  .hero-sub {
    font-size: 1rem;
    width: auto;
  }
}
