:root {
  --primary-color: #8f0f4b;
  --secondary-color: #8f6d0f;
  --accent-color: #0f7e8f;
  --light-color: #f2f2f2;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --max-width: 1100px;
  --shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  --nav-bar-width: 200px;
  --nav-bar-width-mobile: 60px;
}
