.about {
    width: 100%;
    height: 100%;
}

.about-container {
    max-width: 1200px;
    margin: 0 auto;
}

.quote {
    margin: 0 auto 30px;
    max-width: 800px;
    color: var(--accent-color);
    padding: 0 15px;
}

.quote-style {
    color: black;
    font-style: italic;
    font-weight: bold;
}

.about-section {
    display: flex;
    justify-content: space-evenly;
}

.about-section div {
    width: 42%;
}

.about-me {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-me p {
    margin: 20px 0;
    padding: 0 15px;
}

.portfolio-pic {
    width: 80%;
    height: auto;
    margin-left: 10%;
}

@media (max-width: 1000px) {
    .about-section {
        display: flex;
        flex-direction: column-reverse;
    }

    .about-section div {
        width: 100%;
        margin: 0 auto;
    }
    .portfolio-pic {
        display: flex;
        width: 350px;
        height: auto;
        margin: 0 auto;
    }
}

@media (max-width: 650px) {
    .portfolio-pic {
        width: 300px;
    }
}

@media (max-width: 650px) {
    .portfolio-pic {
        width: 200px;
    }
}