nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 2rem;
    position: fixed;
    z-index: 2;
    width: 100%;
    top: 0;
    border-bottom: solid 2px var(--secondary-color);
    opacity: 0.9;
    background-color: var(--primary-color);
}

img {
    width: 50px;
    height: 50px;
}

.logo1 {
    margin-left: 10%;
}

.logo2 {
    margin-right: 10%;
}

.nav-links {
    display: flex;
}

li {
    margin: 0 20px;
    font-size: 24px;
}

.nav-item {
    color: whitesmoke;
}
.nav-item :hover {
    color: black;
    transition: ease-in-out .25s;
}
.nav-item a {
  color: whitesmoke;
}

@media (max-width: 900px) {
    li {
        font-size: 20px;
    }
}

@media (max-width: 650px) {
    .logo1 {
        display: none;
    }
    .logo2 {
        display: none;
    }
    li {
        margin: 5px 10px;
    }
    nav {
        padding: 20px;
    }
}

@media (max-width: 400px) {
    li {
        font-size: 16px;
        margin: 4px 7px;
    }
    nav {
        margin: 0 auto;
        justify-content: space-evenly;
    }
}
