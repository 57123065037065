.contact {
    width: 100%;
    height: auto;
    margin-bottom: 5%;
}

.contact-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.contact-container p {
    font-size: 24px;
    padding: 0 6px;
}

.social-media {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 3.5rem;
}

.fa-linkedin {
    color:#0271ae;
}
.fa-linkedin :hover {
    color: whitesmoke;
    background-color:#0271ae;
    transition: ease-in-out .2s;
    border-radius: 5px;
}

.fa-github {
    color:#000000;
    margin: 0 5rem;
}
.fa-github :hover {
    color: whitesmoke;
    background-color:#000000;
    transition: ease-in-out .2s;
    border-radius: 5px;
}

.fa-twitter {
    color:#55ACEE;
}
.fa-twitter :hover {
    color: whitesmoke;
    background-color:#55ACEE;
    transition: ease-in-out .2s;
    border-radius: 5px;
}

.contact-me {
    margin-top: -20px;
    margin-bottom: 2rem;
}
.fa-linkedin::before,
.fa-github::before,
.fa-twitter::before {
  content: none;
}

@media (max-width: 400px) {
    .fa-github {
        margin: 0 3rem;
    }
}